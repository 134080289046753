@font-face {
font-family: 'subFont';
src: url(/_next/static/media/b5af3835996028c3-s.p.ttf) format('truetype');
font-display: swap;
}

@font-face {
font-family: 'subFont';
src: url(/_next/static/media/4b292663cc06c065-s.p.woff) format('woff');
font-display: swap;
}

@font-face {
font-family: 'subFont';
src: url(/_next/static/media/524a56fdb4e34421-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'subFont Fallback';src: local("Arial");ascent-override: 87.17%;descent-override: 9.30%;line-gap-override: 0.44%;size-adjust: 103.66%
}.__className_c2c5bb {font-family: 'subFont', 'subFont Fallback'
}

